
import { PropertyDisplayDto, UnitContractProjectionDto } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import {
  DocumentDatasource, DocumentRecipient, MessageSendingSource, PropertyConnection, RecipientDistribution, Warnings,
} from '../interfaces';
import { SyncDataDto } from 'api/app';

interface Props {
  children: React.ReactNode;
}


export const MessageSendingContext = createContext<
  | {
      source: MessageSendingSource,
      setSource: React.Dispatch<React.SetStateAction<MessageSendingSource>>;
      basedOnPreference: boolean;
      setBasedOnPreference: React.Dispatch<React.SetStateAction<boolean>>;
      dirty: boolean;
      setDirty: React.Dispatch<React.SetStateAction<boolean>>;
      warnings: Warnings,
      setWarnings: React.Dispatch<React.SetStateAction<Warnings>>,
    }
  | undefined
>(undefined);

export const useMessageSendingContext = (usageName: string) => {
  const messageSendingContext = useContext(MessageSendingContext);

  if (messageSendingContext === undefined) {
    throw new Error(`${usageName} must be used within a MessageSendingContextProvider`);
  }

  return messageSendingContext;
};

export const MessageSendingPropertyContext = createContext<
  | {
      properties: DefaultDataInterface<PropertyDisplayDto[]>;
      setProperties: React.Dispatch<React.SetStateAction<DefaultDataInterface<PropertyDisplayDto[]>>>;
      propertyConnections: PropertyConnection[];
      setPropertyConnections: React.Dispatch<React.SetStateAction<PropertyConnection[]>>;
    }
  | undefined
>(undefined);

export const useMessageSendingPropertyContext = (usageName: string) => {
  const messageSendingPropertyContext = useContext(MessageSendingPropertyContext);

  if (messageSendingPropertyContext === undefined) {
    throw new Error(`${usageName} must be used within a MessageSendingContextProvider`);
  }

  return messageSendingPropertyContext;
};

export const DocumentContext = createContext<
  | {
      documentDatasource: DefaultDataInterface<DocumentDatasource[]>;
      setDocumentDatasource: React.Dispatch<React.SetStateAction<DefaultDataInterface<DocumentDatasource[]>>>;
      documentIndexes: Map<number, number>;
      setDocumentIndexes: React.Dispatch<React.SetStateAction<Map<number, number>>>;
    }
  | undefined
>(undefined);

export const useDocumentContext = (usageName: string) => {
  const documentContext = useContext(DocumentContext);

  if (documentContext === undefined) {
    throw new Error(`${usageName} must be used within a MessageSendingContextProvider`);
  }

  return documentContext;
};


export const RecipientContext = createContext<
  | {
      documentRecipients: DocumentRecipient[],
      recipientDistributions: RecipientDistribution[];
      contracts: DefaultDataInterface<UnitContractProjectionDto[]>;
      contactsLoading: boolean;
    }
  | undefined
>(undefined);

export const useRecipientContext = (usageName: string) => {
  const recipientContext = useContext(RecipientContext);

  if (recipientContext === undefined) {
    throw new Error(`${usageName} must be used within a MessageSendingContextProvider`);
  }

  return recipientContext;
};


export const RecipientUpdaterContext = createContext<
  | {
      setRecipientDistributions: React.Dispatch<React.SetStateAction<RecipientDistribution[]>>;
      setDocumentRecipients: React.Dispatch<React.SetStateAction<DocumentRecipient[]>>;
      setContracts: React.Dispatch<React.SetStateAction<DefaultDataInterface<UnitContractProjectionDto[]>>>;
      setContactsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    }
  | undefined
>(undefined);

export const useRecipientUpdaterContext = (usageName: string) => {
  const recipientUpdaterContext = useContext(RecipientUpdaterContext);

  if (recipientUpdaterContext === undefined) {
    throw new Error(`${usageName} must be used within a MessageSendingContextProvider`);
  }

  return recipientUpdaterContext;
};

export const SearchContext = createContext<
  | {
      documentSearchString: string;
      setDocumentSearchString: React.Dispatch<React.SetStateAction<string>>;
      recipientSearchString: string;
      setRecipientSearchString: React.Dispatch<React.SetStateAction<string>>;
    }
  | undefined
>(undefined);

export const useSearchContext = (usageName: string) => {
  const searchcontext = useContext(SearchContext);

  if (searchcontext === undefined) {
    throw new Error(`${usageName} must be used within a MessageSendingContextProvider`);
  }

  return searchcontext;
};

export const SyncDataContext = createContext<
  | {
      contractsSyncData: DefaultDataInterface<SyncDataDto[]>,
      setContractsSyncData: React.Dispatch<React.SetStateAction<DefaultDataInterface<SyncDataDto[]>>>,
      contactsSyncData: DefaultDataInterface<SyncDataDto[]>,
      setContactsSyncData: React.Dispatch<React.SetStateAction<DefaultDataInterface<SyncDataDto[]>>>
    }
  | undefined
>(undefined);

export const useSyncDataContext = (usageName: string) => {
  const syncDataContext = useContext(SyncDataContext);

  if (syncDataContext === undefined) {
    throw new Error(`${usageName} must be used within a MessageSendingContextProvider`);
  }

  return syncDataContext;
}

const MessageSendingContextProvider = ({ children }: Props) => {
  const [recipientDistributions, setRecipientDistributions] = useState<RecipientDistribution[]>([]);
  const [basedOnPreference, setBasedOnPreference] = useState<boolean>(true);
  const [documentIndexes, setDocumentIndexes] = useState<Map<number, number>>();
  const [dirty, setDirty] = useState<boolean>(false);
  const [warnings, setWarnings] = useState<Warnings>({});
  const [source, setSource] = useState<MessageSendingSource>();

  const [properties, setProperties] = useState<DefaultDataInterface<PropertyDisplayDto[]>>(DEFAULT_DATA([]));
  const [propertyConnections, setPropertyConnections] = useState<PropertyConnection[]>();
  const [documentDatasource, setDocumentDatasource] = useState<DefaultDataInterface<DocumentDatasource[]>>(DEFAULT_DATA([]));
  const [documentRecipients, setDocumentRecipients] = useState<DocumentRecipient[]>();
  const [contracts, setContracts] = useState<DefaultDataInterface<UnitContractProjectionDto[]>>(DEFAULT_DATA([]));
  const [contactsLoading, setContactsLoading] = useState<boolean>(false);

  const [documentSearchString, setDocumentSearchString] = useState('');
  const [recipientSearchString, setRecipientSearchString] = useState('');

  const [contractsSyncData, setContractsSyncData] = useState<DefaultDataInterface<SyncDataDto[]>>(DEFAULT_DATA([]));
  const [contactsSyncData, setContactsSyncData] = useState<DefaultDataInterface<SyncDataDto[]>>(DEFAULT_DATA([]));

  const providerValue = useMemo(() => ({
    basedOnPreference,
    setBasedOnPreference,
    dirty,
    setDirty,
    warnings,
    setWarnings,
    source,
    setSource,
  }), [basedOnPreference, setBasedOnPreference, dirty, setDirty, warnings, setWarnings, source, setSource]);

  const documentContextValue = useMemo(() => ({
    documentDatasource,
    setDocumentDatasource,
    documentIndexes,
    setDocumentIndexes,
  }), [documentDatasource, setDocumentDatasource, documentIndexes, setDocumentIndexes]);


  const recipientContextValue = useMemo(() => ({
    recipientDistributions,
    documentRecipients,
    contracts,
    contactsLoading
  }), [recipientDistributions, documentRecipients, contracts, contactsLoading]);

  const recipientUpdatersValue = useMemo(() => ({
    setRecipientDistributions,
    setDocumentRecipients,
    setContracts,
    setContactsLoading
  }), [setRecipientDistributions, setDocumentRecipients, setContracts, setContactsLoading]);

  const propertyContextValue = useMemo(() => ({
    properties, setProperties, propertyConnections, setPropertyConnections,
  }), [properties, setProperties, propertyConnections, setPropertyConnections]);

  const searchContextValue = useMemo(() => ({
    documentSearchString,
    setDocumentSearchString,
    recipientSearchString,
    setRecipientSearchString,
  }), [documentSearchString, setDocumentSearchString, recipientSearchString, setRecipientSearchString]);

  const syncDataContextValue = useMemo(() => ({
    contractsSyncData,
    setContractsSyncData,
    contactsSyncData,
    setContactsSyncData
  }), [contractsSyncData, setContractsSyncData, contactsSyncData, setContactsSyncData]);

  return (
    <MessageSendingContext.Provider value={providerValue}>
      <MessageSendingPropertyContext.Provider value={propertyContextValue}>
        <DocumentContext.Provider value={documentContextValue}>
          <RecipientContext.Provider value={recipientContextValue}>
            <RecipientUpdaterContext.Provider value={recipientUpdatersValue}>
              <SearchContext.Provider value={searchContextValue}>
                <SyncDataContext.Provider value={syncDataContextValue}>
                  {children}
                </SyncDataContext.Provider>
              </SearchContext.Provider>
            </RecipientUpdaterContext.Provider>
          </RecipientContext.Provider>
        </DocumentContext.Provider>
      </MessageSendingPropertyContext.Provider>
    </MessageSendingContext.Provider>
  );
};

export default MessageSendingContextProvider;
