import { Modal } from 'antd';
import {
  MessageControllerApi,
  MessageCreateDtoLetterDispatchTypeEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import _ from 'lodash';
import { Warnings } from 'pages/MessageSendingPage/interfaces';
import {
  useDocumentContext, useMessageSendingContext, useRecipientContext,
} from 'pages/MessageSendingPage/services/MessageSendingContext';
import { messageSendingTranslations } from 'pages/MessageSendingPage/translations';
import { useContext, useState } from 'react';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';

export const useExecuteButton = () => {
  const { tl } = useContext(LanguageContext);
  const { goBack } = useContext(OverlayContext);

  const [loading, setLoading] = useState<boolean>(false);

  const messageSendingContext = useMessageSendingContext('useExecutButton');
  const recipientContext = useRecipientContext('useExecuteButton');
  const documentContext = useDocumentContext('useExecuteButton');

  if (messageSendingContext === undefined) {
    throw new Error('useExecuteButton must be used within a MessageSendingContextProvider');
  }

  const { documentDatasource, documentIndexes } = documentContext;
  const { recipientDistributions, documentRecipients, contactsLoading } = recipientContext;

  const {
    setDirty, setWarnings, source,
  } = messageSendingContext;

  const { apiConfiguration } = useContext(AuthContext);
  const messageControllerApi = new MessageControllerApi(apiConfiguration('accounting'));

  const disabled = contactsLoading || _.isEmpty(documentDatasource.data);

  const execute = () => {
    const { missingRecipientForDocuments, missingAddress, missingDispatchError, invalidEPOSTAddress, contractNotSyncedToPortal } = areRecipientsValid();

    if (!missingRecipientForDocuments && !missingAddress && !missingDispatchError && !invalidEPOSTAddress && !contractNotSyncedToPortal) {
      Modal.confirm({
        title: tl(messageSendingTranslations.confirmationModal.title),
        content: <p>{tl(messageSendingTranslations.confirmationModal.content)}</p>,
        okText: tl(messageSendingTranslations.confirmationModal.send),
        cancelText: tl(messageSendingTranslations.confirmationModal.cancel),
        okButtonProps: { className: 'Button' },
        cancelButtonProps: { className: 'Button' },
        onOk: () => onExecute(),
        width: 450,
      });
    } else {
      const notificationDescripiton = [];
      if (missingRecipientForDocuments) notificationDescripiton.push(tl(messageSendingTranslations.notifications.missingRecipients));
      if (missingDispatchError) notificationDescripiton.push(tl(messageSendingTranslations.notifications.missingDispatchTypes));
      if (missingAddress) notificationDescripiton.push(tl(messageSendingTranslations.notifications.missingAddresses));
      if (invalidEPOSTAddress) notificationDescripiton.push(tl(messageSendingTranslations.notifications.invalidEpostAddresses));
      if (contractNotSyncedToPortal) notificationDescripiton.push(tl(messageSendingTranslations.notifications.contractNotSynced));

      showNotification({
        type: 'warning',
        message: tl(messageSendingTranslations.notifications.sendError),
        description: notificationDescripiton,
      });
    }
  };

  const areRecipientsValid = () => {
    const newWarnings: Warnings = {
      contactsWithMissingAddresses: [],
      contactsWithInvalidEPOSTAddress: [],
      contactsWithMissingDispatch: [],
      documentsWithMissingRecipient: [],
      contractsNotSyncedToPortal: [],
    };
    let missingAddress = false;
    let invalidEPOSTAddress = false;
    let missingDispatchError = false;
    let missingRecipientForDocuments = false;
    let contractNotSyncedToPortal = false;

    const documents = documentDatasource.data?.flatMap(p => p.children).flatMap(d => d?.children || d);
    documents.forEach((doc) => {
      if (documentRecipients.findIndex(dr => dr.documentId === doc.id) === -1) {
        missingRecipientForDocuments = true;
        newWarnings.documentsWithMissingRecipient.push(doc.name);
      }
    });
    recipientDistributions.forEach((recipient) => {
      // ensure it has a selected dispatch type
      if (_.isEmpty(recipient.distributionType)) {
        missingDispatchError = true;
        newWarnings.contactsWithMissingDispatch.push(recipient.contactId);
      }
      if (!recipient.hasAddress) {
        missingAddress = true;
        if (!newWarnings.contactsWithMissingAddresses.includes(recipient.contactId)) {
          newWarnings.contactsWithMissingAddresses.push(recipient.contactId);
        }
      }
      if(!_.isEmpty(recipient.distributionType) && recipient.distributionType.includes(MessageCreateDtoLetterDispatchTypeEnum.EPOST) && !recipient.hasValidAddressForEPOST) {
        invalidEPOSTAddress = true;
        if (!newWarnings.contactsWithInvalidEPOSTAddress.includes(recipient.contactId)) {
          newWarnings.contactsWithInvalidEPOSTAddress.push(recipient.contactId);
        }
      }
      if (!_.isEmpty(recipient.distributionType) && recipient.distributionType.includes(MessageCreateDtoLetterDispatchTypeEnum.PORTAL) && !recipient.isContractSynced) {
        contractNotSyncedToPortal = true;
        newWarnings.contractsNotSyncedToPortal.push({ property: recipient.property, contract: recipient.contract });
      }
    });

    setWarnings(newWarnings);
    return { missingRecipientForDocuments, missingAddress, missingDispatchError, invalidEPOSTAddress, contractNotSyncedToPortal };
  };

  const onExecute = () => {
    setLoading(true);
    const messages = [];

    // skip rows where no contact is selected
    const relevantRecipients = recipientDistributions.filter(recipient => recipient.contactId !== undefined);
    relevantRecipients.forEach((recipient) => {
      // not sorted list of document ids of recipient
      const documentIdsOfRecipient = documentRecipients
        .filter(dr => dr.contactId === recipient.contactId && dr.contractId === recipient.contractId)
        .map(dr => dr.documentId);

      // sorted list of documents of recipient
      const documentsOfRecipient = documentDatasource.data
        ?.flatMap(prp => prp.children)
        ?.flatMap(sc => (sc.children ? sc.children : sc))
        ?.filter(d => documentIdsOfRecipient.includes(d.id))
        .sort((d1, d2) => documentIndexes[d1.id] - documentIndexes[d2.id]);

      const propertyId = documentsOfRecipient[0]?.propertyId;
      const messageSourceType = source?.sourceType ?? documentsOfRecipient[0]?.sourceType;
      const messageSourceId = source?.sourceId ?? documentsOfRecipient[0]?.sourceId;
      const messageName = documentsOfRecipient[0]?.name ?? tl(messageSendingTranslations.sourceType[messageSourceType]);

      const sortedDocumentIds = documentsOfRecipient.map(d => d.id);

      recipient.distributionType.forEach((dt) => {
        messages.push({
          appendRecipientAddress: true,
          letterDispatchType: dt as unknown as string,
          documentIds: sortedDocumentIds,
          name: messageName,
          propertyId,
          recipientContactId: recipient.contactId,
          contractId: recipient.contractId,
          sourceId: messageSourceId,
          sourceType: messageSourceType,
        });
      });
    });

    messageControllerApi.createMessagesUsingPOST({ messageCreateDtos: messages })
      .then(() => {
        setLoading(false);
        setDirty(false);
        showNotification({
          type: 'success',
          message: tl(messageSendingTranslations.notifications.sendSuccess),
        });

        // manual messages can no longer be downloaded, because the final document is generated by scheduled job
        goBack({ state: { messagesCreated: true } });
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        showNotification({
          type: 'error',
          message: tl(messageSendingTranslations.notifications.sendError),
        });
      });
  };

  return {
    execute,
    loading,
    disabled,
  };
};
